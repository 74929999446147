// src/App.js
import React, { useState } from 'react';
import './App.css';
import { yowzasToWord, wordsToYowzas } from './yowza'

function App() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const translateToYowza = (text) => {
    // Replace with your actual translation logic
    // For simplicity, this example just returns the input text as-is
    return wordsToYowzas(text).join(' ');
  };

  const translateFromYowza = (text) => {
    // Replace with your actual translation logic
    // For simplicity, this example just returns the input text as-is
    return yowzasToWord(text).join(' ');
  };

  const handleInputTextChange = (e) => {
    const newText = e.target.value;
    setInputText(newText);
    const translatedText = translateToYowza(newText);
    setOutputText(translatedText);
  };

  const handleOutputTextChange = (e) => {
    const newText = e.target.value;
    setOutputText(newText);
    const translatedText = translateFromYowza(newText);
    setInputText(translatedText);
  };

  return (
    <div className="app-container">
      <div className="app-title">
        <h1>Yowza Translator!</h1>
        <p> Using poll options and hashtags, you can have 10 "yowzas" per post</p><p> Use this translater to send real words!</p>

      </div>
      <div className="textarea-container">
        
        <textarea
          className="input-textarea"
          placeholder="Enter text to translate"
          value={inputText}
          onChange={handleInputTextChange}
        ></textarea>
        <textarea
          className="output-textarea"
          placeholder="Yowza"
          value={outputText}
          onChange={handleOutputTextChange}
        ></textarea>
      </div>
    </div>
  );
}

export default App;