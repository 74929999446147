const cleanString = (str) => str.replace(/[^a-zA-Z]/g, '').toLowerCase();

const collectFreqs = (str) => {
    let freqCount = ""
    let last = ""
    let count = ""

    for (const letter of cleanString(str)){
        if (letter !== last) {
            freqCount += count
            count = 1 
            last = letter
        }
        else {
            count++
        }
    }

    freqCount += count

    return freqCount
}

const sumFreqs = (freqs) => {
    freqs = [...freqs]
    let sum = 0

    for (let freq of freqs){
        sum += (+freq)
    }
    return sum
}

// this assumes that exactly one or two letters are doubled
function getDoubledLetters(freqs) {
    freqs = [...freqs]
    let doubles = []
    
    for (const [i, freq] of freqs.entries()) {
        if (freq == 2) doubles.push(i)
    }

    if (doubles.length == 1) return doubles[0]
    
    switch (doubles.toString()) {
        case '0,1':
            return 0
        case '0,2':
            return 1
        case '0,3':
            return 2
        case '0,4':
            return 3
        case '1,2':
            return 4
        case '1,3':
            return 5
        case '1,4':
            return 6
        case '2,3':
            return 7
        case '2,4':
            return 8
        case '3,4':
            return 9
    }
    return -1
}

// this assumes there is exactly one letter which is tripled
function getTripledLetter(freqs){
    freqs = [...freqs]
    let doubles = []
    
    for (const [i, freq] of freqs.entries()) {
        if (freq == 3) return i
    }

    return -1
}

function strToBitNum(str) {
    str = str.replace(/[^a-zA-Z]/g, '')
    const binaryString = str
        .split('')
        .map(letter => letter === letter.toUpperCase() ? '0' : '1')
        .join('');

    const decimalNumber = parseInt(binaryString, 2);

    return decimalNumber;
}



function classifyCapitalizations(str){
    let capitalizationId = strToBitNum(str)

    let freqs = collectFreqs(str)
    let totalChars = sumFreqs(freqs)

    // no letter duplication
    if (totalChars == 5) return capitalizationId
    // 0 - 31 now taken

    // one double
    if (totalChars == 6) return 32 + getDoubledLetters(freqs) * 64 + capitalizationId
    // 0 - 351 now taken 

    // two doubles or one triple
    if (totalChars == 7){

        // two doubles
        if(freqs.includes("2")) return 352 + getDoubledLetters(freqs) * 128 + capitalizationId
        // 0 - 1631 now taken

        // one triple
        if(freqs.includes("3")) return 1632 + getTripledLetter(freqs) * 128 + capitalizationId
        // 0 - 2271 now taken
    }

    return -1
}

function classifyPunctuation(str){

    str = str.replace(/[^\.\?!]/g, '')
    const inverseVals = {0: '?', 1: '.', 2: '!',  3: '?'}

    const valsShift = {'.': 1, '!': 2, '?': 3}
    const vals = { '?': 0, '.': 1, '!': 2}

    if (str.length == 0) return 0
    if (str.length == 1) return valsShift[str]

    const char1 = valsShift[str[0]] * 3
    const char2 = vals[str[1]]

    return char1 + char2 + 1
}

 function strToNum(str){
    let caps = classifyCapitalizations(str)
    let punc = classifyPunctuation(str)

    // console.log(`Caps: ${caps}`)
    // console.log(`Punc: ${punc}`)

    return punc * 2272 + caps
}

function yowzaToWord(yowza) {
    // console.log(strToNum(yowza))
    return vocab[strToNum(yowza)]
}

export function yowzasToWord(yowzas) {
    return yowzas.replace('#', '').replace(/\s/, ' ').split(' ').filter(Boolean).map(
        (yowza) => yowzaToWord(yowza)
    )
}

 function numToCaps(num){
    let caps = num % 2272

    let str = 'yowza'

    // 5 characters, no duplication
    if (caps < 32) {
        const capId = caps
        return [capId.toString(2), str]
    }

    // 6 chars, 1 double
    if (caps < 352) {
        const capId = (caps - 32) % 64
        const doubledLetter = Math.floor( (caps - 32) / 64)

        str = str.slice(0, doubledLetter) + str[doubledLetter] + str.slice(doubledLetter)

        return [capId.toString(2), str]
    }

    // 7 chars, 2 doubles
    if(caps < 1632) {
        const capId = (caps - 352) % 128
        const doubledLetters = Math.floor( (caps - 352) / 128 )
        // console.log(doubledLetters)
        let doubled;

        switch (doubledLetters){
            case 0:
                doubled = [0, 1]
                break
            case 1:
                doubled = [0, 2]
                break
            case 2:
                doubled = [0, 3]
                break
            case 3:
                doubled = [0, 4]
                break
            case 4: 
                doubled = [1, 2]
                break
            case 5:
                doubled = [1, 3]
                break
            case 6:
                doubled = [1, 4]
                break
            case 7:
                doubled = [2, 3]
                break
            case 8:
                doubled = [2, 4]
                break
            case 9:
                doubled = [3, 4]
                break
            
        }
        // console.log(caps)
        // console.log(doubled)

        str = str.slice(0, doubled[0]) + str[doubled[0]].repeat(1) + str.slice(doubled[0], doubled[1]) + str[doubled[1]].repeat(1)  + str.slice(doubled[1])
        return [capId.toString(2), str]
    }

    if(caps < 2272){
        const capId = (caps - 1632) % 128
        const tripledLetter = Math.floor( (caps - 1632) / 128)

        str = str.slice(0, tripledLetter) + str[tripledLetter].repeat(2) + str.slice(tripledLetter)

        return [capId.toString(2), str]
    }

}

function capsToString(bitArray, string) {
    if (string.length < bitArray.length) {
      throw new Error("String and bit array must have equal lengths.");
    }

    while (string.length > bitArray.length){
        bitArray = "0"+bitArray
    }
  
    const result = [];
  
    for (let i = 0; i < string.length; i++) {
      const char = string[i];
      const isUpperCase = bitArray[i] == 0;
      result.push(isUpperCase ? char.toUpperCase() : char.toLowerCase());
    }
  
    return result.join('');
}

function numToPunc(num){
    const punc = Math.floor(num / 2272)

    const inverseVals = {0: '?', 1: '.', 2: '!',  3: '?'}

    if (punc == 0) return ''

    if (punc < 4) return inverseVals[punc]

    const char1 = Math.floor((punc - 1) / 3)
    // 4,5,6 ==> 1; 7,8,9 ==> 2, 10,11,12 ==> 3

    const char2 = (punc - 1) % 3

    

    return inverseVals[char1] + inverseVals[char2]

    // const a = str[str.length - 2]
    // const b = str[str.length - 1]
    // const vals = {'.': 1, '!': 2, '?': 3}
    // let sum = 0
    // if (vals[a] != undefined){
    //     sum += vals[a]*3
        
    // } if (vals[b] != undefined){
    //     sum += vals[b]
    // }
}

export function wordToNum(word){
    for(const [i, v] of vocab.entries()) {
        if (word === v) return i
    }
    return -1
}


const filePath = '/30k.txt'; // Adjust the path based on the actual location in the public directory

const vocab = []

await fetch(filePath)
  .then(response => response.text())
  .then(data => {
    const lines = data.split('\n'); // Split the text into an array of lines

    lines.forEach(line => {
      vocab.push(line.trim());
    })
  })
  .catch(error => console.error('Error fetching the data:', error));

// console.log(vocab)
// console.log(wordToYowza('hello'))

function wordToYowza(word){
    let num = wordToNum(cleanString(word))
    if (num == -1) return "[WORD NOT IN VOCABULARY]"

    const caps = numToCaps(num)
    return capsToString(caps[0], caps[1]) + numToPunc(num)
}

export function wordsToYowzas(words){
    const toReturn = []
    words = words.replace(/\s/, ' ').split(' ').filter(Boolean)
    
    for (let [i, word] of words.entries()){
        word = word.toLowerCase()
        if (i == 0) toReturn.push(wordToYowza(word))
        else if (i == 1) toReturn.push("#"+wordToYowza(word)+"\n\n")
        else if (Math.floor(i / 2) == 1 || Math.floor(i / 2) == 4){
            if (i % 2) toReturn.push("#"+wordToYowza(word)+"\n")
            else toReturn.push(wordToYowza(word))
        }
        else {
            if (i % 2) toReturn.push(wordToYowza(word)+"\n")
            else toReturn.push("#"+wordToYowza(word))
            
        }
    }
    return toReturn
}